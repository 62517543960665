// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

// Font Awesome
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

// Flowbite
import "flowbite/dist/flowbite.turbo.js";


FontAwesome.config.mutateApproach = 'sync'