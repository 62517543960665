import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "navbar" ]

  scroll(val) {
    // based on window scrollY, fade background opacity
    if (window.scrollY < 32) {
      this.element.classList.remove("bg-white", "dark:bg-gray-900", "shadow-lg", "md:shadow-xl", "bg-opacity-95", "dark:bg-opacity-95");
    } else {
      this.element.classList.add("bg-white", "dark:bg-gray-900", "shadow-lg", "md:shadow-xl", "bg-opacity-95", "dark:bg-opacity-95");
    }
  }
}