import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("locs search res controller", this.element)
  }

  added() {
    console.log("added")
    this.element.remove()
  }
}